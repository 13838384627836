// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
@use "./variables.scss" as theme;

// Plus imports for other components in your app.
@import "@angular/material/theming";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$Rept-dark: mat.define-palette(theme.$Rept-primary, dark);
$Rept-medium: mat.define-palette(theme.$Rept-primary, medium);
$Rept-light: mat.define-palette(theme.$Rept-primary, light);

// The warn palette is optional (defaults to red).
$Rept-warn: mat.define-palette(theme.$Rept-warn, primary);

// The background Color Pallet
$Rept-background: mat.define-palette(theme.$Rept-primary, background);

// Font Family Change
$Rept-typography: mat.define-typography-config($font-family: "GothamBold, Helvetica Neue, Helvetica, Arial, sans-serif",
    $headline-1: mat-typography-level(112px, 112px, 300),
    $headline-2: mat-typography-level(56px, 56px, 400),
    $headline-3: mat-typography-level(45px, 48px, 400),
    $headline-4: mat-typography-level(34px, 40px, 400),
    $headline-5: mat-typography-level(24px, 32px, 400),
    $headline-6: mat-typography-level(20px, 32px, 500),
    $subtitle-1: mat-typography-level(16px, 28px, 400),
    $subtitle-2: mat-typography-level(15px, 24px, 400),
    $body-1: mat-typography-level(14px, 24px, 500, GothamNarrowBook),
    $body-2: mat-typography-level(14px, 20px, 400, GothamNarrowBook),
    $caption: mat-typography-level(12px, 20px, 400, GothamNarrowBook),
    $button: mat-typography-level(14px, 14px, 500),
  );

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$Rept-theme: mat.define-light-theme((color: (primary: $Rept-dark,
        accent: $Rept-medium,
        light: $Rept-light,
        warn: $Rept-warn,
        background: $Rept-background,
      ),
      typography: $Rept-typography,
    ));

// Export Colors for use in CSS
$primary: mat-color($Rept-dark);
$accent: mat-color($Rept-medium);
$light: mat-color($Rept-light);
$warn: mat-color($Rept-warn);
$background: mat-color($Rept-background);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
//@include mat.core($Rept-typography);
@include mat.typography-hierarchy($Rept-typography);
@include mat.all-component-themes($Rept-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
  margin: 0;
}

// body {
//   margin: 0;
//   font-family: GothamBold, "Helvetica Neue", Helvetica, Arial, sans-serif;
// }

main {
  width: 100vw;
  height: auto;
  min-height: calc(100vh - 110px - 45px - 104px);
  margin: 0;
  background-color: $background;
  padding-bottom: 16px;
}

mat-card {
  margin-top: 16px;
  border-radius: 0px;
}

.mat-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button {
  border-radius: 0px;
  color: white;
}

.primary-blue {
  color: $primary;
}

.bg-primary-blue {
  background-color: $primary;
}

.accent-blue {
  color: $accent;
}

.bg-accent-blue {
  background-color: $accent;
}

.light-blue {
  color: $light;
}

.bg-light-blue {
  background-color: $light;
}

.font-gotham {
  font-family: "GothamBold", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.font-gotham-book {
  font-family: "GothamBook", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.font-gotham-book-medium {
  font-family: "GothamBookMedium", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}

.font-gotham-narrow {
  font-family: "GothamNarrowBook", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}

.font-gotham-narrow-bold {
  font-family: "GothamNarrowBold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a.active {
  text-decoration: none;
}

.snackbar {
  display: flex !important;
  justify-content: center;
}

.snackbar-sucess {
  background: $primary;
}

.snackbar-error {
  background: $warn;
}

.mat-form-field.mat-primary {
  display: flex;
}

::ng-deep .mat-form-field-wrapper {
  width: 100%;
}

::ng-deep .mat-paginator-navigation-first {
  color: #0b2d71;
}

::ng-deep .mat-paginator-navigation-previous {
  color: #0b2d71;
}

::ng-deep .mat-paginator-navigation-next {
  color: #0b2d71;
}

::ng-deep .mat-paginator-navigation-last {
  color: #0b2d71;
}

.header-back-color {
  background-color: #75caeb !important;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: end;
}

.BlackText {
  font-size: 9pt;
  font-family: Arial;
  color: #333333;
}

.outage-info {
  background-color: #009dd0;
  color: white;
}

.SubHeader {
  font-size: 9pt;
  font-family: Arial;
  color: White;
  background-color: #75caeb;
  text-align: center;
}

.PartHoursExceeded {
  color: White;
  background-color: Red !important;
}

.header-color {
  background-color: #009dd0 !important;
  color: white !important;
}

.mat-mdc-dialog-container {
  resize: both !important;
  background: #fff;
  overflow: auto;
}

// .cdk-overlay-pane {
//   width: unset !important;
//   height: unset !important;

//   min-width: "800px";
//   min-height: "480px";
//   max-width: 100% !important;
// }

// .mat-dialog-container {
//   min-width: "800px";
//   min-height: "480px";

//   max-width: 100%;
//   max-height: 100% !important;
//   resize: both;
// }

.WarningMsgArea {
  border: 1px solid #c0c000;
  padding: 5px 10px 10px 10px;
  margin: 10px 0 5px 0;
  text-align: left;
  width: 800px;
  display: block;
}

.warningmsg {
  font-style: inherit;
  font-size: 10pt;
  font-family: Arial;
  color: #c0c000;
}

.Header {
  font-size: 9pt;
  font-family: Arial;
  color: White;
}

.report-heading {
  border-bottom: 1px solid black;
  font-size: 12pt;
  font-weight: bold;
  text-align: center;
}

.ErrorMsgArea {
  border: 1px solid red;
  padding: 5px 10px 10px 35px;
  margin: 10px 0 5px 0;
  text-align: left;
  background: url(/assets/images/alert.png) no-repeat 5px 3px;
  width: 850px;
  display: block;
}

.errormsg {
  font-style: inherit;
  font-size: 10pt;
  font-family: Arial;
  color: Red;
}

.toast-container .ngx-toastr {
  // // position: relative;
  // // overflow: hidden;
  // // margin: 0 0 6px;
  // // padding: 15px 15px 15px 50px;
  // // width: 300px;
  // // border-radius: 15px !important;
  // // background-position: 15px center;
  // // background-repeat: no-repeat;
  // // background-size: 24px;
  // // box-shadow: 0 0 12px #999;
  // // color: #fff;
  // // border-image-source: linerar-gradient(red, purple) !important;
  // // border: 4px solid green !important;
  // // border-image-source: linear-gradient(red, purple) !important;
  // // border-image-slice: 20 !important;
  font-size: 15px;
  // //background-color: #c0c000;
}

.FirstUse {
  color: White;
  background-color: Green;
  padding: 2px 2px 2px 2px;
  width: 23px;
}

.Scrapped {
  color: White;
  background-color: Red;
  padding: 2px 2px 2px 2px;
  width: 23px;
}

.HoursExceeded {
  border: 2px solid black;
  padding: 2px 2px 2px 2px;
  width: 23px;
}

.OutagesEntry{
  height: 20px;
  width: 210px;
  margin-top: 9px;
  margin-left: 15px;
}

.Bold {
  font-weight: bold;
}

.heading {
  border: 0px none;
}

.beginreport {
  border-top: 1px solid black;
}